<template>
  <div id="containerbar">
    <div class="rightbar">
      <div class="breadcrumbbar">
        <div class="row align-items-center">
          <div class="col-md-9 col-lg-9">
            <h4 class="page-title">
              Auditoría
            </h4>
            <div class="breadcrumb-list">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <a href="/dashboard">{{ $t("cancellation.home") }}</a>
                </li>
                <li class="breadcrumb-item">
                  <a href="#">{{ $t(title) }}</a>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <div class="contentbar">
        <div class="row">
          <div class="col-lg-12 col-xl-12">
            <div class="card">
              <AlertMessageComponent ref="alertMessageComponent" />
              <div class="card-header">
                <div class="row">
                  <div class="col">
                    <h5 class="card-title">
                      <i class="feather icon-check-square mr-2"></i
                      >{{ $t(titleAction) }}
                    </h5>
                  </div>
                  <div class="col-md-4 col-lg-3">
                    <v-select
                      class="br"
                      outlined
                      dense
                      v-model="type"
                      :items="orderHistoryList"
                      label="Seleccione una opción"
                      data-vv-name="select"
                    ></v-select>
                  </div>
                </div>
              </div>
              <AuditTableComponent
                v-if="showTable"
                :headers="headers"
                :pagesAtributes="pagesAtributes"
                :changeTable="changeTable"
                :modelTable="modelTable"
                :shipments="type == 2"
                :isPdf="
                  type == 2
                    ? withPermissionName(permissions.AUD_SHIP_DL_PDF_02)
                    : withPermissionName(permissions.AUD_REM_DL_PDF_02)
                "
                :isPdfOw="
                  type == 2
                    ? withPermissionName(permissions.AUD_SHIP_OW_PDF_03)
                    : withPermissionName(permissions.AUD_REM_OW_PDF_03)
                "
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Vuex

import { AuditRequest } from "@/core/request";
import AuditTableComponent from "@/components/audit/AuditTableComponent";
import { mapMutations, mapState } from "vuex";

import {
  REMMISION_AUDIT,
  STARTING_ORDER_AUDIT,
} from "@/core/DataTableHeadersLocale";

export default {
  data() {
    return {
      title: "Auditoría",
      permissions: this.$PermissionConstants,
      headers: [],
      showTable: false,
      idUser: "",

      type: 0,

      loading: null,
      pagesAtributes: {
        currentPage: 0,
        itemsPerPage: 15,
        search: "",
        sortBy: "",
        sortDesc: true,
        sortFilters: {},
      },
      modelTable: {
        content: [],
        pageable: {},
        totalPages: 0,
        totalElements: 0,
      },
    };
  },
  computed: {
    ...mapState("auth", ["branchOffice", "actionState"]),

    titleAction() {
      if (this.type == 1) {
        return "Auditoría";
      }
      return "Auditoría";
    },
  },
  methods: {
    ...mapMutations({
      listAllRequisitionGob: "remission/SET_REQUISITION",
    }),
    alert(type, message) {
      this.$refs.alertMessageComponent.reloadComponent(type, message);
    },
    alertModal(type, message) {
      this.$refs.alertMessageComponentModal.reloadComponent(type, message);
    },
    withPermissionName(namePermission) {
      return this.$UtilFront.withPermissionName(namePermission);
    },
    clearModel() {
      this.modelTable = {
        content: [],
        pageable: {},
        totalPages: 0,
        totalElements: 0,
      };
    },
    async changeTable() {
      this.clearModel();
      if (this.type == 1) {
        await this.listAllRemissionAudit(this.pagesAtributes);
      } else {
        await this.listAllShipmentsAudit(this.pagesAtributes);
      }
    },
    // Entradas
    async listAllRemissionAudit(pagesAtributes) {
      this.loading = this.$UtilFront.getLoadding();
      let payload = {
        page: pagesAtributes,
        idUser: this.$UtilFront.getDataUser().idUser,
        idBranchOffice: this.$UtilFront.getDataUser().mainBranch,
      };
      await AuditRequest.getAllRemissionAudit(payload)
        .then((response) => {
          let data = response.data;
          this.modelTable = data;
        })
        .catch((error) => {
          this.alert("error", error.message);
        })
        .finally(() => {
          this.loading.hide();
        });
    },
    // Salidas
    async listAllShipmentsAudit(pagesAtributes) {
      let payload = {
        page: pagesAtributes,
        idUser: this.$UtilFront.getDataUser().idUser,
        idBranchOffice: this.$UtilFront.getDataUser().mainBranch,
      };
      try {
        this.loading = this.$UtilFront.getLoadding();
        const response = await AuditRequest.getAllShipmentAudit(payload);
        let data = response.data;
        this.modelTable = data;
      } catch (error) {
        console.log(error);
        this.alert("error", error.message);
      } finally {
        this.loading.hide();
      }
    },
  },
  created() {
    //Almaceno el id del usuario
    this.orderHistoryList = [];
    this.idUser = this.$UtilFront.getDataUser().idUser;
    if (this.withPermissionName(this.permissions.AUD_REM_LIST_01)) {
      this.orderHistoryList.push({
        text: "Entradas",
        value: 1,
      });
    }
    if (this.withPermissionName(this.permissions.AUD_SHIP_LIST_01)) {
      this.orderHistoryList.push({
        text: "Salidas",
        value: 2,
      });
    }
    this.type = this.orderHistoryList[0].value;
    this.headers = REMMISION_AUDIT();
    this.listAllRequisitionGob(this.branchOffice.idBranchoffice);
  },
  watch: {
    async type() {
      this.showTable = false;
      this.type == 1
        ? (this.headers = REMMISION_AUDIT())
        : (this.headers = STARTING_ORDER_AUDIT());

      this.pagesAtributes = {
        currentPage: 0,
        itemsPerPage: 15,
        search: "",
        sortBy: "",
        sortDesc: true,
        sortFilters: {},
      };
      this.$nextTick(() => {
        this.showTable = true;
      });
    },
  },
  components: {
    AuditTableComponent,
  },
};
</script>
