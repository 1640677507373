<template>
  <div class="p-2">
    <v-data-table
      :headers="headers"
      :items="modelTable.content"
      :search="search"
      fixed-header
      height="auto"
      :options.sync="options"
      :server-items-length="modelTable.totalElements"
      :page.sync="page"
      :footer-props="{
        'items-per-page-text': $t('table.item_table'),
        'items-per-page-all-text': $t('table.all'),
        'items-per-page-options': [15, 30, 50, 100],
      }"
      :loading-text="$t('table.loading')"
      :no-data-text="$t('table.no_results_available')"
      :no-results-text="$t('table.no_results_search')"
      dense
    >
      <template v-slot:top>
        <div class="row justify-end p-2 pb-0">
          <div class="col-sm-12 pb-0 col-md-6 col-lg-3 col-xl-3">
            <vc-date-picker
              :locale="lang"
              color="green"
              v-model="dateMov"
              is-range
              @input="datePickerChangeHandler"
              popover-align="center"
            >
              <template v-slot="{ inputEvents }">
                <div class="flex justify-center items-center">
                  <v-text-field
                    :readonly="true"
                    v-on="inputEvents.start"
                    :value="modalDateRangeCalendar"
                    :label="$t('label.date_range')"
                    @change="dateFilterChange()"
                    :append-outer-icon="
                      modalDateRangeCalendarValid
                        ? 'mdi-eraser'
                        : 'mdi-calendar'
                    "
                    @click:append-outer="modalDateRangeClean"
                  ></v-text-field>
                </div>
              </template>
            </vc-date-picker>
          </div>
          <div class="col-sm-12 pb-0 col-md-6 col-lg-3 col-xl-3">
            <AllBranchOfficeComponentClear
              ref="branchOfficeDestination"
              v-on:branchChange="branchChange"
              branchLabel="Sucursal origen"
              :branchOfficeList="branchOfficeList"
              :isDisabled="isDisabled"
            />
          </div>
          <div class="col-sm-12 col-md-6 col-lg-3 col-xl-3">
            <v-autocomplete
              class="br"
              v-model="userFilter"
              :items="listUserFilter"
              @change="userFilterChange(true)"
              :label="
                !pagesAtributes.sortFilters.branchOriginFilter &&
                !pagesAtributes.sortFilters.branchOfficeSelectOriginFilter
                  ? 'Elige una sucursal origen'
                  : 'Realizado por'
              "
              data-vv-name="select"
              clearable
              :disabled="
                !pagesAtributes.sortFilters.branchOfficeSelectOriginFilter &&
                  !pagesAtributes.sortFilters.branchOriginFilter
              "
            ></v-autocomplete>
          </div>
          <div class="col-sm-12 col-md-6 col-lg-3 col-xl-3">
            <v-select
              v-if="shipments"
              class="br"
              v-model="filterStartingOrder"
              :items="statusFilterStartingOrder"
              @change="filterStartingOrderChange()"
              clearable
              label="Estatus"
              data-vv-name="select"
            ></v-select>
          </div>
          <div class="col-sm-12 col-md-6 col-lg-3 col-xl-3">
            <v-autocomplete
              v-if="!shipments"
              class="br"
              @change="filterRequisitionChange()"
              v-model="filterRequisition"
              :items="requisitionGovList"
              clearable
              label="Pedido"
            ></v-autocomplete>
          </div>
          <div class="col-sm-12 col-md-6 col-lg-3 col-xl-3">
            <v-autocomplete
              v-if="!shipments"
              v-model="statusFilter"
              :items="statusListFilter"
              clearable
              @change="statusFilterChange()"
              item-value="value"
              label="Tipo de entrada"
              data-vv-name="select"
            >
              <template v-slot:selection="data">
                {{ $t(data.item.name) }}
              </template>
              <template v-slot:item="data">
                {{ $t(data.item.name) }}
              </template></v-autocomplete
            >
          </div>
          <div
            class="col-sm-12 col-md-6 col-lg-5 col-xl-4 pb-1 pr-6 text-right"
          >
            <v-text-field
              v-model="search"
              v-on:keyup.enter="searchValue"
              :suffix="search == '' ? '' : 'enter'"
              class="br"
              :disabled="loading_search"
              :label="$t('table.search')"
              ><template v-slot:append>
                <span class="pt-2">
                  <i
                    class="feather icon-corner-down-left"
                    v-show="search != ''"
                  ></i>
                </span> </template
            ></v-text-field>
          </div>
        </div>
      </template>

      <template v-slot:[`item.requisitionType`]="{ item }">
        {{ utilFront.typeRemissionName(item.remissionSubtype) }}
      </template>

      <template v-slot:[`item.observations`]="{ item }">
        {{ item.observations == "" ? "-" : item.observations }}
      </template>

      <template v-slot:[`item.statusShipment`]="{ item }">
        <ChipTableCommonPagination :status="item.statusShipment" />
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip v-if="isPdfOw" top>
          <template v-slot:activator="{ on, attrs }">
            <span
              class="hand mr-3"
              @click="reportOwncloudPDF(item)"
              v-bind="attrs"
              v-on="on"
            >
              <i class="fa fa-files-o"></i>
            </span>
          </template>
          <span>Reporte Escaneado</span>
        </v-tooltip>
        <v-tooltip v-if="isPdf" top>
          <template v-slot:activator="{ on, attrs }">
            <span
              class="hand text-fmedical-red"
              @click="downloadPDF(item)"
              v-bind="attrs"
              v-on="on"
            >
              <i class="fa fa-file-pdf-o"></i>
            </span>
          </template>
          <span>{{ $t("table.download_PDF") }}</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <ButtonPrintComponent />
  </div>
</template>

<script>
import ButtonPrintComponent from "@/common/table/ButtonPrintComponent";
import AllBranchOfficeComponentClear from "@/common/select/AllBranchOfficeComponentClear";
import { BranchOfficeRequest, OrderRequest } from "@/core/request";

import ChipTableCommonPagination from "@/common/table/ChipTableCommonPagination";
import { ExcelRequest } from "@/core/request";
import remissionFilter from "@/assets/json/remissionType.json";

import { mapState } from "vuex";
import moment from "moment";

export default {
  props: {
    headers: [],
    shipments: false,
    isPdf: false,
    isPdfOw: false,
    pagesAtributes: Object,
    changeTable: Function,
    modelTable: Object,
  },
  data() {
    return {
      search: "",
      dataList: [],
      options: {},
      page: 1,
      loading_search: false,
      utilFront: this.$UtilFront,
      branchOfficeList: [],
      userFilter: "",
      listUserFilter: [],
      dateMov: "",
      isDisabled: false,
      filterStartingOrder: null,
      dateModelMov: {
        startDate: "",
        endDate: "",
      },
      modelConfig: {
        type: "string",
        mask: "DD/MM/YYYY",
      },
      branch_general: null,
      statusListFilter: remissionFilter,
      statusFilterStartingOrder: [
        { value: 1, text: "Pendiente de Finalizar" },
        { value: 2, text: "Picking" },
        { value: 3, text: "Validación de Picking" },
        { value: 4, text: "Pendiente de Envío" },
        { value: 5, text: "Tránsito" },
        { value: 6, text: "Recepción" },
        { value: 7, text: "Cancelado" },
      ],
      filterRequisition: null,
      statusFilter: null,
    };
  },
  watch: {
    async search() {
      if (this.search == "") {
        await this.searchValue();
      }
    },
    options: {
      async handler() {
        await this.pagination();
      },
      deep: true,
    },
  },
  computed: {
    ...mapState("remission", {
      suppliers: "listSupplier",
      requisitionGovList: "listRequisition",
    }),
    ...mapState("locales", ["lang"]),
    modalDateRangeCalendar() {
      if (
        this.dateMov == "" ||
        this.dateMov == null ||
        this.dateMov == undefined
      ) {
        return "";
      }

      this.dateModelMov.startDate = moment(this.dateMov.start).format(
        "YYYY-MM-DD"
      );
      this.dateModelMov.endDate = moment(this.dateMov.end).format("YYYY-MM-DD");

      this.$emit("dateChange", this.dateModelMov);

      return `${moment(this.dateMov.start).format("YYYY-MM-DD")} al ${moment(
        this.dateMov.end
      ).format("YYYY-MM-DD")}`;
    },
    modalDateRangeCalendarValid() {
      if (
        this.dateMov == "" ||
        this.dateMov == null ||
        this.dateMov == undefined
      ) {
        return false;
      }
      return true;
    },
  },
  async mounted() {
    await this.getListBranchOffice(2);
  },
  methods: {
    /**
     * Table pagination. / Paginado de la tabla.
     * @method
     */
    async pagination() {
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      this.pagesAtributes.sortBy = sortBy[0];
      this.pagesAtributes.sortDesc = sortDesc[0];
      this.pagesAtributes.currentPage = page - 1;
      this.pagesAtributes.itemsPerPage = itemsPerPage;
      await this.changeTable();
    },
    /**
     * Search input. / Input de b�squeda.
     * @method
     */
    async searchValue() {
      try {
        this.loading_search = true;
        this.pagesAtributes.search = this.search;
        await this.performSearch(this.pagesAtributes);
        this.page = 1;
      } catch (error) {
        console.error(error);
      } finally {
        this.loading_search = false;
      }
    },
    async performSearch(pagesAtributes) {
      await this.changeTable(pagesAtributes);
    },
    async filterStartingOrderChange() {
      if (this.filterStartingOrder) {
        this.pagesAtributes.sortFilters.statusSelectFilter = this.filterStartingOrder;
      } else {
        delete this.pagesAtributes.sortFilters.statusSelectFilter;
      }
      await this.performSearch(this.pagesAtributes);
    },
    async statusFilterChange() {
      if (this.statusFilter) {
        this.pagesAtributes.sortFilters.typeSelectFilter = this.statusFilter;
      } else {
        delete this.pagesAtributes.sortFilters.typeSelectFilter;
      }
      await this.performSearch(this.pagesAtributes);
    },
    async filterRequisitionChange() {
      if (this.filterRequisition) {
        this.pagesAtributes.sortFilters.requisitionFilter = this.filterRequisition;
      } else {
        delete this.pagesAtributes.sortFilters.requisitionFilter;
      }
      await this.performSearch(this.pagesAtributes);
    },
    async datePickerChangeHandler() {
      if (this.dateMov) {
        let start = moment(this.dateMov.start).format("YYYY-MM-DD");
        let end = moment(this.dateMov.end).format("YYYY-MM-DD");

        this.pagesAtributes.sortFilters.dateRangeFilter = {
          from: start + " 00:00:00",
          to: end + " 23:59:59",
        };
      } else {
        delete this.pagesAtributes.sortFilters.dateRangeFilter;
      }
      await this.performSearch(this.pagesAtributes);
    },
    modalDateRangeClean() {
      this.dateMov = "";
      this.dateModelMov.startDate = "";
      this.dateModelMov.endDate = "";
    },
    async getListBranchOffice(orderType, idUser) {
      try {
        this.loading = this.$UtilFront.getLoadding();
        this.isDisabled = true;
        const payload = {
          orderType: null,
          ...(idUser && { idUser }),
        };
        const response = await BranchOfficeRequest.listBranchOfficeUsers(
          payload
        );
        this.branchOfficeList = response.data;
      } catch (error) {
        console.error(error);
      } finally {
        this.isDisabled = false;
        this.loading.hide();
      }
    },
    async userFilterChange(payload) {
      if (this.userFilter && payload) {
        this.pagesAtributes.sortFilters.userSelectFilter = parseInt(
          this.userFilter
        );
      } else {
        if (!this.userFilter)
          delete this.pagesAtributes.sortFilters.userSelectFilter;
      }
      if (payload) {
        this.isDisabled = true;
        await this.performSearch(this.pagesAtributes);
        this.isDisabled = false;
      }
    },
    async branchChange(item) {
      this.branch_general = item;
      this.userFilter = null;
      this.userFilterChange(false);
      if (item) {
        this.pagesAtributes.sortFilters.branchOfficeSelectOriginFilter = item;
      } else {
        delete this.pagesAtributes.sortFilters.branchOfficeSelectOriginFilter;
        await this.getListBranchOffice(2);
        this.branch_general = null;
      }
      if (!this.userFilter) await this.listAllUserFilter(item);
      await this.performSearch(this.pagesAtributes);
    },
    async listAllUserFilter(idBranchOffice) {
      const payload = {
        ...(idBranchOffice && { idBranchOffice }),
      };
      this.loading = this.$UtilFront.getLoadding();
      await OrderRequest.getAllUserList(payload)
        .then((response) => {
          this.listUserFilter = response.data;
        })
        .catch((error) => {
          this.$toast.error(error.message);
        })
        .finally(() => {
          this.loading.hide();
        });
    },
    downloadPDF(item) {
      if (this.shipments) return this.downloadShipmentPdf(item);
      this.downloadRemissionPDF(item);
    },
    downloadShipmentPdf(item) {
      let send =
        "idOrder=" + item.orderId + "&" + "idShipment=" + item.idShipment;
      ExcelRequest.reportOrderPdf(send);
      this.$toast.success("Generando documento PDF");
    },
    downloadRemissionPDF(item) {
      let send =
        "idRemision=" +
        item.idRemmision +
        "&" +
        "idSucursal=" +
        item.idBranchOfficeOrigin;
      ExcelRequest.reportRemmisionPdf(send);
      this.$toast.success("Descargando documento PDF");
    },
    reportOwncloudPDF(item) {
      let payload = {
        idReport: this.shipments ? item.idShipment : item.idRemmision,
        typeReport: this.shipments ? 2 : 1,
        idUser: this.$UtilFront.getDataUser().idUser,
        idBranchOffice: this.$UtilFront.getDataUser().mainBranch,
      };

      ExcelRequest.reportOwncloud(payload)
        .then((response) => {
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: "application/pdf",
            })
          );
          window.open(url);
          this.$toast.success("Archivo generado.");
        })
        .catch(() => {
          this.$toast.error("Recurso no encontrado.");
        });
    },
  },
  components: {
    ButtonPrintComponent,
    ChipTableCommonPagination,
    AllBranchOfficeComponentClear,
  },
};
</script>
